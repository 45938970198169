(function() {
  const getCookie = (name) => {
    console.log('COOKIE', document.cookie);
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    }

    return 'Not Found';
  };

  const setBluDisplayIDS = () => {
    const BeeswaxUserID = getCookie('bluBeeswaxUserID');
    const InternalUserID = getCookie('bluInternalUserID');
    const SiteUserID = getCookie('bluSiteUserID');

    document.getElementById("InternalUserID").innerHTML=InternalUserID;
    document.getElementById("BeeswaxUserID").innerHTML=BeeswaxUserID;
    document.getElementById("SiteUserID").innerHTML=SiteUserID;
  }

  setBluDisplayIDS();
})()

